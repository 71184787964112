import qnbLogo from "assets/images/qnbLogo.png";

export const handleLogo = (logo: string) => {
  switch (logo) {
    case "QNB_LOGO":
      return qnbLogo;
    default:
      return logo;
  }
};
