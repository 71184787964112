import React from "react";
import { Link } from "react-router-dom";

import { AppBar, Toolbar, Container } from "@mui/material";

import { getEnvVarsStorage } from "helpers";
import { getUser } from "helpers/sessionToken";
import { UserAvatarWithMenu, LocaleSwitcher } from "components/common";
import { appBar, toolBar, appLogo } from "./styles";
import { AppRoutes } from "types";
import { handleLogo } from "../config";


const Header: React.FC = () => {
  const user = getUser();
  const envs = getEnvVarsStorage();

  return (
    <AppBar position="static" sx={appBar}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={toolBar}>
          <Link to={AppRoutes.HOME}>
            <img src={handleLogo(envs?.logo ?? "")} alt="logo" style={appLogo} />
          </Link>
          <div>
            <LocaleSwitcher />
            {user && <UserAvatarWithMenu />}
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
