import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Avatar, IconButton, MenuItem } from "@mui/material";

import MenuWithArrow from "./MenuWithArrow";
import useMenuState from "hooks/useMenuState";
import { locales, LOCALES_MAP, isPrivateBank } from "constants/globals";
import { getEnvVarsStorage } from "helpers";
import { LocaleTypes } from "types";

const LocaleSwitcher = () => {
  const { i18n } = useTranslation();
  const [anchorEl, handleClick, handleClose] = useMenuState();

  const envLocales = useMemo(() => {
    const env = getEnvVarsStorage()?.appI18n;
    if (env) return locales.filter(l => env.includes(l));
    return locales;
  }, []);
  const envs = getEnvVarsStorage()

  return (
    <>
    {!isPrivateBank(envs?.tenantName)? <IconButton onClick={handleClick} size="small" sx={{ mx: "7px" }}>
        <Avatar
          sx={{ width: 24, height: 24 }}
          src={`/images/flags/Country=${LOCALES_MAP[i18n.language as LocaleTypes].flag}.svg`}
        />
      </IconButton>: null }
      
      <MenuWithArrow anchorEl={anchorEl} onClose={handleClose} onClick={handleClose}>
        {envLocales.map(locale => {
          return (
            <MenuItem key={locale} onClick={() => i18n.changeLanguage(locale)}>
              <Avatar
                sx={{ width: 24, height: 24, mr: "10px" }}
                src={`/images/flags/Country=${LOCALES_MAP[locale].flag}.svg`}
              />
              {LOCALES_MAP[locale].text}
            </MenuItem>
          );
        })}
      </MenuWithArrow>
    </>
  );
};

export default LocaleSwitcher;
