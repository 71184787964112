import React, { Suspense, lazy, useState, useEffect, useRef, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useTranslation } from "react-i18next";
import deepmerge from "deepmerge";

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";

import * as locales from "@mui/material/locale";
import { createTheme, responsiveFontSizes, ThemeOptions, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";

import theme from "config/theme";
import queryClient from "config/reactQuery";
import { AxiosInstanceProvider } from "config/axiosConfig";
import PageLoadingSpinner from "components/common/PageLoadingSpinner";
import Layout from "components/layouts";
import ProtectedRoute from "components/common/ProtectedRoute";
import { getEnvVars } from "requests";
import { setEnvVarsStorage } from "helpers";
import { LOCALES_MAP, isPrivateBank, isQNB } from "constants/globals";
import { AppRoutes, LocaleTypes } from "types";
import JpmFavicon from "assets/images/JpmFavicon.png";
import QNBFavicon from "assets/images/QNBFavicon.png";
import Favicon from "assets/images/favicon.svg";

const Consents = lazy(() => import("./pages/Consents"));
const Login = lazy(() => import("./pages/Login"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const QNBPrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/QNBPolicy"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const QNBTermsAndConditions = lazy(() => import("./pages/QNBTermsAndConditions"));
const NotFound = lazy(() => import("./pages/NotFound"));

function App() {
  const { i18n } = useTranslation();
  const [direction, setDirection] = useState<ThemeOptions["direction"]>("ltr");
  const [envs, setEnvs] = useState<Record<string, any> | null>(null);
  const [emotionCache, setEmotionCache] = useState(() =>
    createCache({
      key: "css",
      stylisPlugins: [prefixer],
    }),
  );

  useEffect(() => {
    (async () => {
      try {
        const vars = await getEnvVars();
        setEnvVarsStorage(vars);
        setEnvs(vars);
      } catch (e) {
        console.log("error returning env vars");
      }
    })();
  }, []);

  const langRef = useRef<string>();
  useEffect(() => {
    if (langRef.current !== i18n.language) {
      langRef.current = i18n.language;
      setDirection(i18n.dir());
      document.dir = i18n.dir();
      if (i18n.dir() === "rtl") {
        setEmotionCache(
          createCache({
            key: "css-rtl",
            stylisPlugins: [prefixer, rtlPlugin],
          }),
        );
      } else {
        setEmotionCache(
          createCache({
            key: "css",
            stylisPlugins: [prefixer],
          }),
        );
      }
    }
  }, [i18n, i18n.language]);

  const themeWithLocale = useMemo(
    () =>
      responsiveFontSizes(
        createTheme(
          deepmerge(theme(envs), { direction }),
          locales[LOCALES_MAP[i18n.language as LocaleTypes].muiLocale as keyof typeof locales],
        ),
      ),
    [direction, envs, i18n.language],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <AxiosInstanceProvider>
        <HelmetProvider>
          <CacheProvider value={emotionCache}>
            <ThemeProvider theme={themeWithLocale}>
              <SnackbarProvider
                maxSnack={5}
                preventDuplicate
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <CssBaseline />

                <Suspense fallback={<PageLoadingSpinner />}>
                  {envs && (
                    <Helmet>
                      <link rel="icon" href={isPrivateBank(envs?.tenantName) ? JpmFavicon : isQNB(envs?.tenantName) ? QNBFavicon : Favicon} />
                    </Helmet>
                  )}
                  <Routes>
                    <Route path={AppRoutes.HOME} element={<Layout />}>
                      <Route index element={<Navigate to={AppRoutes.LOGIN} replace />} />
                      <Route path={AppRoutes.LOGIN} element={<Login />} />
                      <Route path={AppRoutes.PRIVACY} element={isQNB(envs?.tenantName) ? <QNBPrivacyPolicy/> : <PrivacyPolicy />} />
                      <Route path={AppRoutes.TERMS} element={isQNB(envs?.tenantName) ? <QNBTermsAndConditions /> : <TermsAndConditions />} />
                    </Route>

                    <Route
                      path={AppRoutes.USER}
                      element={
                        <ProtectedRoute>
                          <Layout />
                        </ProtectedRoute>
                      }
                    >
                      <Route index element={<Consents />} />
                    </Route>

                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
              </SnackbarProvider>
            </ThemeProvider>
          </CacheProvider>
        </HelmetProvider>
      </AxiosInstanceProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
