import React, { createContext, PropsWithChildren, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

import { getSession, removeSession, setSession } from "helpers/sessionToken";
import { axiosRequests } from "requests";
import { IAxiosBaseContext, AppRoutes } from "types";

const AxiosBaseContext = createContext<IAxiosBaseContext | undefined>(undefined);

export const AxiosInstanceProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const navigate = useNavigate();
  const client = useQueryClient();

  const axiosInstance = axios.create({
    baseURL: `/api`,
  });
  const requests = axiosRequests(axiosInstance);

  axiosInstance.interceptors.request.use(
    config => {
      const session = getSession();
      if (session?.access_token) {
        // TODO: Remove ts-ignore
        // @ts-ignore
        config.headers.setAuthorization(`Bearer ${session.access_token}`);
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    async err => {
      const originalConfig = err.config;
      // Access Token was expired
      if (err.response?.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const session = getSession();

          if (!!session) {
            const data = await requests.refreshToken(session.refresh_token);
            setSession({ ...data, timestamp: new Date().getTime() });
            // originalConfig.headers.authorization = `Bearer ${data.access_token}`;
            axiosInstance.defaults.headers.common.authorization = `Bearer ${data.access_token}`;
          }

          return axiosInstance(originalConfig);
        } catch (_error) {
          removeSession();
          client.clear();
          // await client.setQueryData(QueryKeys.USER, () => undefined);
          await navigate(AppRoutes.LOGIN);

          return Promise.reject(_error);
        }
      }

      return Promise.reject(err);
    },
  );

  return <AxiosBaseContext.Provider value={requests}>{children}</AxiosBaseContext.Provider>;
};

export const useGetAxiosRequests = () => {
  const context = useContext(AxiosBaseContext);
  if (!context) {
    throw new Error("you dont have axios requests");
  }
  return context;
};
