import { LocaleTypes } from "types";

export const HEADER_HEIGHT = 72;
export const FOOTER_HEIGHT = 56;

export const ROWS_IN_TABLE = [5, 10, 25, 50, 100];

export const locales: LocaleTypes[] = ["en", "pt", "ar"];
export const LOCALES_MAP = {
  en: { flag: "GB", text: "English", muiLocale: "enUS" },
  pt: { flag: "PT", text: "Portuguese", muiLocale: "ptPT" },
  ar: { flag: "SA", text: "Arabic", muiLocale: "arEG" },
};

export const publicFolderPath = process.env.PUBLIC_URL;

export const PAYMENT_TYPES = [
  {
    label: "Single",
    value: "payments",
  },
  {
    label: "Bulk",
    value: "bulkPayments",
  },
  {
    label: "Periodic",
    value: "periodicPayments",
  },
];

export const timeFormat = "dd MMM yyyy";

export const isPrivateBank = (tenantName?: string) => tenantName === "privatebank-jpmorgan";
export const isQNB = (tenantName?: string) => tenantName === "qnb";

