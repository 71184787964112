export const colors = {
  linksNormal: "#009DE9",
  chipSuccess: "#0A9485",
  chipSuccessBg: "#D4EFEC",
  chipError: "#72003D",
  chipErrorBg: "#FFDEE6",
  jpmBrown: "#8d6a4b",
};

export default colors;
