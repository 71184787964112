import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { Theme } from "@mui/material";

export const flexContainer: SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: { xs: "column", sm: "row" },
};

export const copyrightWrapper: SxProps<Theme> = {
  py: "15px",
};

export const footerLinks: SxProps<Theme> = {
  mr: "24px",
};
