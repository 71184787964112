import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { styled, Button, Container, Typography, Link as MUILink } from "@mui/material";

import { AppRoutes } from "types";
import { isPrivateBank } from "constants/globals";
import { getEnvVarsStorage } from "helpers";

const PolicyWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  padding: "0.5rem 0",
  backgroundColor: theme.palette.grey[100],
}));

const PolicyDialog = () => {
  const [agreed, setAgreed] = useState<boolean>(false);

  useEffect(() => {
    setAgreed(localStorage.getItem("policy-agreed") === "true");
  }, []);

  const toggle = () => {
    localStorage.setItem("policy-agreed", "true");
    setAgreed(true);
  };
  const envs = getEnvVarsStorage();
  return agreed ? null : (
    <PolicyWrapper>
      <Container sx={{ display: "flex", alignItems: "center" }}>
        { isPrivateBank(envs?.tenantName) ? 
        <Typography>
        By using this website you agree to our
        <MUILink href={`${envs?.tenantUrl}/privacy`} sx={{ mx: "8px" }} target='_blank'>
          Privacy policy
        </MUILink>{" "}
        and
        <MUILink href={`${envs?.tenantUrl}/terms`} sx={{ mx: "8px" }} target='_blank'>
          Terms and Conditions
        </MUILink>
      </Typography> : 
        <Typography>
          By using this website you agree to our
          <MUILink component={Link} to={AppRoutes.PRIVACY} sx={{ mx: "8px" }}>
            Privacy policy
          </MUILink>{" "}
          and
          <MUILink component={Link} to={AppRoutes.TERMS} sx={{ mx: "8px" }}>
            Terms and Conditions
          </MUILink>
        </Typography>}
        <Button variant="contained" color="secondary" size="small" sx={{ ml: 1 }} onClick={toggle}>
          Got it
        </Button>
      </Container>
    </PolicyWrapper>
  );
};

export default PolicyDialog;
