export enum QueryKeys {
  USER = "user",
  CONSENT = "consent",
  AUDIT = "audit",
}

export enum DialogTypes {
  VIEW,
  REVOKE,
}

export enum AppRoutes {
  HOME = "/",
  LOGIN = "/login",
  PRIVACY = "/privacy-policy",
  TERMS = "/terms-and-conditions",
  USER = "/user/authorisations",
}

export enum TabsEnum {
  ACCOUNTS = "accounts",
  PAYMENTS = "payments",
  PERIODIC = "periodicPayments",
  BULK = "bulkPayments",
  PIISP = "funds",
}

export interface IAxiosBaseContext {
  exchange: (code: string) => Promise<TokenExchangeData>;
  refreshToken: (refreshToken: string) => Promise<TokenExchangeData>;
  logOut: (tokens: Pick<SessionData, "id_token" | "refresh_token">) => Promise<unknown>;
}

export interface Account {
  iban: string;
  bban: string;
  currency: string;
}

export interface CurrentTabApi {
  accounts: string;
  payments: string;
  periodicPayments: string;
  bulkPayments: string;
  funds: string;
}

export interface Amount {
  amount: string;
  currency: string;
}

export interface ConsentLink {
  href: string;
}

export interface BulkPaymentEntry {
  creditorAccount: Account;
  creditorName: string;
  instructedAmount: Amount;
  remittanceInformationUnstructured: string;
}

export interface ConsentData {
  clientId: string;
  consentId: string;
  consentStatus: string;
  frequencyPerDay: number;
  lastActionDate: string;
  recurringIndicator: boolean;
  validUntil: string;
  access?: {
    accounts: Account[];
    balances?: Account[];
    transactions?: Account[];
  };
  executionDate?: string;
  startDate?: string;
  creditorAccount?: Account;
  debtorAccount?: Account;
  batchBookingPreferred?: boolean;
  payments: BulkPaymentEntry[];
  iban?: string;
  bban?: string;
  amount?: string;
  creditorName?: string;
  instructedAmount?: Amount;
  currency?: string;
  paymentId?: string;
  remittanceInformationUnstructured?: string;
  requestedExecutionDate: string;
  transactionStatus?: string;
  dateCreated: string;
  _links?: {
    self: ConsentLink;
    status: ConsentLink;
  };
}

export type ConsentResponseData = {
  data: ConsentData[];
  total: number;
};

export interface TokenExchangeData {
  id_token: string;
  access_token: string;
  refresh_token: string;
  timestamp: number;
}

export interface ParsedToken {
  acr: string;
  "allowed-origins": string[];
  azp: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  preferred_username: string;
  scope: string;
  session_state: string;
  sid: string;
  sub: string;
  typ: string;
  user_id: string;
  username: string;
}

export interface SessionData extends TokenExchangeData {
  timestamp: number;
}

export type LocaleTypes = "en" | "pt" | "ar";
