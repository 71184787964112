import axios, { AxiosInstance } from "axios";
import { QueryFunctionContext } from "react-query";

import { getSession } from "helpers/sessionToken";
import { getEnvVarsStorage, getConsentTypes, randomNum } from "helpers";
import { ConsentData, IAxiosBaseContext, QueryKeys, CurrentTabApi, TabsEnum } from "types";
const {v4: uuidv4} = require("uuid");


export const sortConsents = (list: ConsentData[]) =>
 //@ts-ignore
  list.sort((a, b) => new Date(b?.lastActionDate) - new Date(a?.lastActionDate));
export const sortPayments = (list: ConsentData[]) =>
//@ts-ignore
  list.sort((a, b) => new Date(b?.requestedExecutionDate) - new Date(a?.requestedExecutionDate));

export const axiosRequests = (instance: AxiosInstance) => {
  return {
    exchange: async code => {
      const { data } = await instance.post("/exchange", { code });
      return data;
    },
    refreshToken: async refresh_token => {
      const { data } = await instance.post("/refresh", { refresh_token });
      return data;
    },
    logOut: async tokens => {
      const { data } = await instance.post("/logout", tokens);
      return data;
    },
  } as IAxiosBaseContext;
};

export const getPsuConsents = async (context: QueryFunctionContext<[QueryKeys.CONSENT, string, number, number, string]>) => {
  const envs = getEnvVarsStorage();
  const currentTabApi = getConsentTypes(envs);
  const page = context.queryKey[2] || 1;
  const limit = context.queryKey[3] || 10;
  const paymentType = context.queryKey[4] || "payments";
  const consentType = currentTabApi[context.queryKey[1] as keyof CurrentTabApi];
  let type = "/consents";

  if (
    context.queryKey[1] === TabsEnum.PAYMENTS ||
    context.queryKey[1] === TabsEnum.BULK ||
    context.queryKey[1] === TabsEnum.PERIODIC
  ) {
    type = "/payments";
  }

  const headers = {
    authorization: `Bearer ${getSession()?.access_token}`,
    "Cache-Control": "no-cache, no-store",
    Pragma: "no-cache",
  };
  const { data } = await axios.get(`${consentType}/psu${type}?pageIndex=${page - 1}&pageSize=${limit}`, { headers });

  const paymentSwitch = (paymentType: string) => {
    switch(paymentType) {
      case TabsEnum.PAYMENTS: return data?.payments?.result;
      case TabsEnum.BULK: return data?.bulkPayments?.result;
      case TabsEnum.PERIODIC: return data?.periodicPayments?.result;
    }
  }
  const paymentPaginationTotal = (paymentType: string) => {
    switch(paymentType) {
      case TabsEnum.PAYMENTS: return data?.payments?.pagination?.total;
      case TabsEnum.BULK: return data?.bulkPayments?.pagination?.total;
      case TabsEnum.PERIODIC: return data?.periodicPayments?.pagination?.total;
    }
  }
  const receivedData = type === "/consents" ? data?.result : paymentSwitch(paymentType)
  const sortedResult = {
    data: type === "/consents" ? sortConsents(receivedData ?? []) : sortPayments(receivedData ?? []),
    total: type === "/consents" ? data?.pagination?.total : paymentPaginationTotal(paymentType)
  };
  return sortedResult;
};

export const getPsuAudit = async ({ consentType, ConsentId }: { consentType: string; ConsentId: string }) => {
  const headers = {
    authorization: `Bearer ${getSession()?.access_token}`,
    "X-Request-ID": uuidv4(),
    "Cache-Control": "no-cache, no-store",
    Pragma: "no-cache",
  };
  const { data } = await axios.get(`${consentType}/consents/${ConsentId}/audit`, { headers });
  return data;
};

export const revokePsuConsents = async ({
  consentType,
  consentId,
  link,
}: {
  consentType: string;
  consentId: string;
  link?: string;
}) => {
  const envs = getEnvVarsStorage();
  const currentTabApi = getConsentTypes(envs);
  const type = currentTabApi[consentType as keyof CurrentTabApi];

  const headers = {
    authorization: `Bearer ${getSession()?.access_token}`,
    "X-Request-ID": uuidv4(),
    "Cache-Control": "no-cache, no-store",
    "Content-Type": "application/json",
    Pragma: "no-cache",
  };
  const { data } = await axios.put(
    `${type}/${!!link ? link : `consents/${consentId}`}?consentStatus=revoke`,
    {},
    { headers },
  );
  return data;
};

export const revokePispConsents = async ({ consentType, paymentType, consentId, link }: { consentType: string; paymentType: string; consentId: string; link?: string }) => {
  const envs = getEnvVarsStorage();
  const currentTabApi = getConsentTypes(envs);
  const type = currentTabApi[consentType as keyof CurrentTabApi];

  const headers = {
    authorization: `Bearer ${getSession()?.access_token}`,
    "X-Request-ID": uuidv4(),
    "Cache-Control": "no-cache, no-store",
    "Content-Type": "application/json",
    Pragma: "no-cache",
  };
  let isPeriodic = paymentType === "periodicPayments" ? "periodic-payments" : "";
  const { data } = await axios.put(
    `${type}/${!!link ? link : `${isPeriodic}/sepa-credit-transfers/${consentId}`}?consentStatus=revoke`,
    {},
    { headers },
  );
  return data;
};

export const getEnvVars = async () => {
  const { data } = await axios.get(`/api/env-vars`);
  return {
    ...data,
    defaultScopes: ["openid", "profile", "email"],
    redirectUri: `${data.hostUrl}/login`,
    nextgenpsd2: {
      apiAispConsents: `${data.bankingApi}/nextgenpsd2-aisp/v1`,
      apiPispConsents: `${data.bankingApi}/nextgenpsd2-pisp/v1`,
      apiPiispConsents: `${data.bankingApi}/nextgenpsd2-piisp/v1`,
    },
    authUrl:
      `${data.authHost}/protocol/openid-connect/auth` +
      `?nonce=${randomNum(7)}` +
      `&client_id=${data.clientId}` +
      `&response_type=${data.authResponseType}` +
      `&redirect_uri=${data.hostUrl}/login` +
      `&scope=${data.authScope}`,
  };
};
