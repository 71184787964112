import { Buffer } from "buffer";

export const randomNum = (n: number) => {
  return Math.floor(Math.pow(10, n) + Math.random() * 9 * Math.pow(10, n - 1));
};

export const objectToQueryString = (object: Record<string, any>) => {
  let newSearchParams = new URLSearchParams();
  for (let key in object) {
    newSearchParams.append(key, object[key]);
  }
  return `?${newSearchParams.toString()}`;
};

export const isConsentExpired = (expiration: string) => {
  return expiration ? new Date(expiration).getTime() - new Date().getTime() < 0 : true;
};

export const jwtDecode = (token: string): Record<string, any> => {
  return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
};

export const round = (value: string | number) => {
  return Number(value).toFixed(2);
};

export const getEnvVarsStorage = (): Record<string, any> | null => {
  if (typeof window !== "undefined") {
    const session = sessionStorage.getItem("env");
    if (session) {
      return JSON.parse(session);
    }
  }
  return null;
};

export const setEnvVarsStorage = (data: Record<string, any>) => {
  if (typeof window !== "undefined") {
    return sessionStorage.setItem("env", JSON.stringify(data));
  }
  return null;
};

export const getConsentTypes = (envs: ReturnType<typeof getEnvVarsStorage>) => ({
  accounts: envs?.nextgenpsd2.apiAispConsents,
  payments: envs?.nextgenpsd2.apiPispConsents,
  periodicPayments: envs?.nextgenpsd2.apiPispConsents,
  bulkPayments: envs?.nextgenpsd2.apiPispConsents,
  funds: envs?.nextgenpsd2.apiPiispConsents,
});
