import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { Theme } from "@mui/material";

export const appBar: SxProps<Theme> = {
  background: theme => theme.palette.common.white,
  borderBottom: theme => `1px solid ${theme.palette.background.secondary}`,
  boxShadow: "none",
  zIndex: theme => theme.zIndex.drawer + 1,
};

export const toolBar: SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  py: "8px",
};

export const appLogo = {
  height: "48px",
  cursor: "pointer",
};
