import React from "react";
import { Outlet } from "react-router-dom";

import { Box } from "@mui/material";

import { Footer, PolicyDialog, Header } from "components/common";
import { container } from "./styles";
import { getEnvVarsStorage } from "helpers";
import { isPrivateBank } from "constants/globals";

const Layout: React.FC = () => {
    const envs = getEnvVarsStorage();
  return (
    <Box>
      <Header />

      <Box component="main" sx={container}>
        <Outlet />
      </Box>

      <Footer />
      {!isPrivateBank(envs?.tenantName) ? <PolicyDialog /> : null}
    </Box>
  );
};

export default Layout;
